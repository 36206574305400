import React from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/seo'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import HelmetExport from 'react-helmet'
import { jsonId } from 'utils'
import * as ResourcesStyles from '../styles/resources.module.css'
import {
  paddedContainer,
  successStoryDesc,
  customerSuccessDivDesktop,
  customerSuccessDivMobile,
  customerSuccessButton,
  successListContainer
} from '../styles/workspace.module.css'
import {
  pageWrapper,
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: {name: "Resources"}) {
        edges {
          node {
            headerSection {
              heroHeading
              heroText
            }
            resourcesFieldGroup {
              customerSuccessHeader
              customerSuccessDescription
              customerSuccessRepeater {
                customerSuccessRepeaterIcon {
                  sourceUrl
                }
                customerSuccessRepeaterTitle
                customerSuccessRepeaterSubtitle
                customerSuccessRepeaterDescription
                buttonText
                buttonLink {
                  link
                }
              }
            }
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Resources = ({ data, pageContext }) => {
  const customerSuccessData =
    data.wordPress.pages.edges[0].node.resourcesFieldGroup
      .customerSuccessRepeater
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug

  const {
    breadcrumb: { crumbs }
  } = pageContext

  const customerSuccessList = ({ customerSuccessData = [] }) => {
    return (
      <>
        <div className={ResourcesStyles.customerFlexContainer}>
          {
            customerSuccessData.length &&
            customerSuccessData.map((item, index) => {
              return (
                <div
                  className={ResourcesStyles.customerSuccessMainContainer}
                  key={index.toString()}>
                  <div className={ResourcesStyles.customerSuccessImageWrapper}>
                    <img src={item.customerSuccessRepeaterIcon.sourceUrl} />
                  </div>
                  <div className={paddedContainer}>
                    <div className={successStoryDesc}>
                      <h4>
                        {item.customerSuccessRepeaterTitle}
                      </h4>
                      <p>
                        {parse(item.customerSuccessRepeaterSubtitle)}
                      </p>
                    </div>
                    <div className={customerSuccessDivDesktop}>
                      <span className={successListContainer}>{parse(item.customerSuccessRepeaterDescription)}</span>
                    </div>
                    <div className={customerSuccessDivMobile}>
                      <span className={successListContainer}>{parse(item.customerSuccessRepeaterDescription.substring(0, 115) + '...')}</span>
                    </div>
                  </div>
                  <div className={ResourcesStyles.customerSuccessButtonWrapper}>
                    <a
                      href={item.buttonLink.link}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <span
                        className={customerSuccessButton}>
                        {item.buttonText}
                      </span>
                    </a>
                  </div>
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Resources Information'
        title='Our Resources' />
      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Resources'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
        <div className={ResourcesStyles.flexContainer}>
          <div className={ResourcesStyles.flexContainerInner}>
            <h1>
              {data.wordPress.pages.edges[0].node.headerSection.heroHeading}
            </h1>
            <p>
              {data.wordPress.pages.edges[0].node.headerSection.heroText}
            </p>
          </div>
        </div>
      </div>
      <div className={ResourcesStyles.customerSuccessContainer}>
        <div className={ResourcesStyles.paddedContainer}>
          <div className={ResourcesStyles.successStoryDesc}>
            <h2>
              {data.wordPress.pages.edges[0].node.resourcesFieldGroup.customerSuccessHeader}
            </h2>
            <span>
              {parse(data.wordPress.pages.edges[0].node.resourcesFieldGroup.customerSuccessDescription)}
            </span>
          </div>
          <div>
            {customerSuccessList({ customerSuccessData })}
          </div>
        </div>
      </div>
    </>
  )
}

Resources.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object.isRequired
}

export default Resources
